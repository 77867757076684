.c-line {
  width: 100%;
  height: 1px;
  background-color: $grey-light;
  margin: $spacing-medium;
}

.c-line-80 {
  width: 80%;
  height: 1px;
  background-color: $grey-light;
  margin: $spacing-medium;
}