
.react-tabs__tab-list {
  display: flex;
  justify-content: space-evenly;

  list-style: none;
  color: $white;
  margin-bottom: $spacing-small;
}

.green-tabs {
  background-color: $green;
  margin-top: -30px;

  .react-tabs__tab--selected {
    color: $green;
  }
}

.blue-tabs {
  background-color: $blue;
  margin-top: -35px;

  .react-tabs__tab--selected {
    color: $blue;
  }
}

.react-tabs__icon {
  width: 30px !important;
  height: 30px !important;
}

.react-tabs__tab {
  padding: 11px;
  outline: none;
  cursor: pointer;
}

.react-tabs__tab--selected {

  background-color: $white;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;

}

@media only screen and (min-width: $screen-large) and (max-width: $screen-other) /* Enormous screen */
{

}

@media only screen and (min-width: $screen-large) and (max-width: $screen-extra-large) /* Big screen */
{

}

@media only screen and (min-width: $screen-medium) and (max-width: $screen-large) /* Big tablet & small desktop*/
{
  .react-tabs__tab-list {
    .react-tabs__tab {
      padding: 3px;
    }
    .react-tabs__icon {
      width: 20px !important;
      height: 20px !important;
    }
  }
}

@media only screen and (min-width: $screen-small) and (max-width: $screen-medium) /* Big mobile & small tablet*/
{

  .react-tabs__tab-list {
    .react-tabs__tab {
      padding: 3px;
    }
    .react-tabs__icon {
      width: 20px !important;
      height: 20px !important;
    }
  }
}

@media only screen and (max-width: $screen-small) /* Mobile */
{
  .react-tabs__tab-list {
    .react-tabs__tab {
      padding: 3px;
    }
    .react-tabs__icon {
      width: 20px !important;
      height: 20px !important;
    }
  }

}
@media only screen and (min-width: $screen-quad-hd) /* Big screen */
{
  .react-tabs{
    &__tab{
      padding: 1.3em;
    }
    &__icon{
      width: 2.5em!important;
      height: 2.5em!important;
    }
    .icon{
      font-size: 2.5em;
    }
  }
}
@media only screen and (min-width: $screen-4k) /* Enormous screen */
{
  .react-tabs{
    &__tab{
      padding: 1.3em;
    }
    &__icon{
      width: 4em!important;
      height: 4em!important;
    }
    .icon{
      font-size: 4em;
    }
  }
}