.o-horizontal-bar {
  .o-horizontal-bar__container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: $spacing-medium-small 0;

    .o-horizontal-bar__bar {
      height: 42px;
      background-color: $grey-lighter;
      width: 70%;
    }

    .o-horizontal-bar__label {
      width: 20%;
    }
  }
}

@media only screen and (min-width: $screen-large) and (max-width: $screen-other) /* Enormous screen */
{

}

@media only screen and (min-width: $screen-large) and (max-width: $screen-extra-large) /* Big screen */
{

}

@media only screen and (min-width: $screen-medium) and (max-width: $screen-large) /* Big tablet & small desktop*/
{

}

@media only screen and (min-width: $screen-small) and (max-width: $screen-medium) /* Big mobile & small tablet*/
{

  .o-horizontal-bar__container {
    flex-direction: column;
  }
  .o-horizontal-bar .o-horizontal-bar__container .o-horizontal-bar__bar {
    width: 90%
  }
  .o-horizontal-bar-grouped__container .o-horizontal-bar-grouped .o-horizontal-bar-grouped__numbers {
    padding: 0 4%;
  }
}

@media only screen and (max-width: $screen-small) /* Mobile */
{
  .o-horizontal-bar__container {
    flex-direction: column;
  }
  .o-horizontal-bar .o-horizontal-bar__container .o-horizontal-bar__bar {
    width: 90%
  }
}