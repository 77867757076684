.o-horizontal-legend {
  display: flex;
  justify-content: center;
  align-items: center;

  .color {
    width: 10px;
    margin: 0 5px;
    height: 10px;
    border-radius: 50%;
  }
}

@media only screen and (min-width: $screen-large) and (max-width: $screen-other) /* Enormous screen */
{

}

@media only screen and (min-width: $screen-large) and (max-width: $screen-extra-large) /* Big screen */
{

}

@media only screen and (min-width: $screen-medium) and (max-width: $screen-large) /* Big tablet & small desktop*/
{
  .o-horizontal-legend {
    display: none;
  }
}

@media only screen and (min-width: $screen-small) and (max-width: $screen-medium) /* Big mobile & small tablet*/
{
  .o-horizontal-legend {
    display: none;
  }
}

@media only screen and (max-width: $screen-small) /* Mobile */
{
  .o-horizontal-legend {
    display: none;
  }
}