.s-infobars {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
}

.clock {
  width: 100%;
  text-align: center;
  color: $black;
  display: none;
}

.tv {
  .clock {
    display: block;
    font-size: 4rem;
  }
}

@media only screen and (min-width: $screen-4k) /* Enormous screen */ {
  .clock {
    font-size: 5rem !important;
  }
}
