.c-chart-label {
    width: 100%;
    text-align: center;
    color: $grey;
    font-size: $font-small;
}
@media only screen and (min-width: $screen-quad-hd) /* Big screen */
{
    .c-chart-label{
        font-size: 1em;
    }
}
@media only screen and (min-width: $screen-4k) /* Enormous screen */
{
    .c-chart-label{
        font-size: 1.7em;
        padding-bottom: 0.4em;
    }

}
