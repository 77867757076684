#tickets {
  padding-bottom: 13px;
}

.s-tickets {
  padding: $spacing-medium 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $white;
}

@media only screen and (min-width: $screen-large) and (max-width: $screen-other) /* Enormous screen */
{

}

@media only screen and (min-width: $screen-large) and (max-width: $screen-extra-large) /* Big screen */
{

}

@media only screen and (min-width: $screen-medium) and (max-width: $screen-large) /* Big tablet & small desktop*/
{

}

@media only screen and (min-width: $screen-small) and (max-width: $screen-medium) /* Big mobile & small tablet*/
{


}

@media only screen and (max-width: $screen-small) /* Mobile */
{
  .s-tickets {
    padding: 10px 0 0 0;
  }
  #tickets {
    padding: 0;
  }
}