@import "./../fonts/ycon";

.icon {
  font-family: "ycon";
  font-size: 1.9rem;
  font-style: unset;
}

@import "./dna/dna.scss";
/* cells */
@import "./cells/c-label.scss";
@import "./cells/c-digitroll.scss";
@import "./cells/c-roller_number.scss";
@import "./cells/c-horizontal-legend-item.scss";
@import "./cells/c-chart-label.scss";
@import "./cells/c-pie-legend-item.scss";
@import "./cells/c-rma-line.scss";
@import "./cells/c-bell.scss";
@import "./cells/c-bar-text.scss";
@import "./cells/c-button.scss";
@import "./cells/c-table-item.scss";
@import "./cells/c-min";
@import "./cells/c-plus";
@import "./cells/c-3dbutton";
@import "./cells/c-line";
@import "./cells/c-settings";
@import "./cells/c-tag";
@import "./cells/c-warehouseLabel.scss";
/* Organs */
@import "./organs/o-header.scss";
@import "./organs/o-horizontal-legend.scss";
@import "./organs/o-numberbox.scss";
@import "./organs/o-notification.scss";
@import "./organs/o-login.scss";
@import "./organs/o-dashboard-card.scss";
@import "./organs/o-react-tabs.scss";
@import "./organs/o-back-arrow";
@import "./organs/o-tv-switch.scss";
@import "./organs/o-active-employees";
@import "./organs/o-product";
/* species */
@import "./species/s-mobile-menu.scss";
@import "./species/s-numberboxes.scss";
@import "./species/s-loader.scss";
@import "./species/s-visitors.scss";
@import "./species/s-rma.scss";
@import "./species/s-tickets.scss";
@import "./species/s-infobars.scss";
/* table */
@import "./organs/o-table-item.scss";
@import "./species/s-table.scss";
/* reviews */
@import "./species/s-reviews.scss";
@import "./organs/o-review-content";
/* Graphs */
@import "./organs/o-gauge.scss";
@import "./organs/o-performance-bar.scss";
@import "./organs/o-horizontal-bar-grouped.scss";
@import "./organs/o-horizontal-bar.scss";
@import "./organs/o-progressbar.scss";
@import "./organs/o-bar-grouped.scss";
@import "./organs/o-line-graph.scss";
@import "./organs/o-bar.scss";
@import "./organs/o-pie.scss";
/* choose dashboard */
@import "./organs/o-dashboard-buttons.scss";
@import "./species/s-dashboards.scss";
/* Auth */
@import "./species/s-google.scss";
@import "./organs/o-auth-popup.scss";
/* tv mode */
@import "./tv.scss";
/* Animations */
@import "animations/rainbow";
@import "animations/spin";
