.o-table-item {
  justify-content: center;
  align-items: center;
  display: flex;
  padding: $spacing-small $spacing-medium;
  border-left: 1px solid $grey-lighter;
  border-right: 1px solid $grey-lighter;
  border-bottom: 1px solid $grey-lighter;
}

.o-table-item__header {
  color: $grey-darker;
  background-color: $grey-lightest;

  .tableTotalPriceHeader {
    width: 25%;

    text-align: right;
  }
}

.o-table-item__product {
  width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.o-table-item__product-price {
  text-align: right;
  width: 30%;
}

.o-table-item__name {
  width: 22%;
  margin-right: 3%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.o-table-item__products {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: $spacing-small 0;
}

.o-table-item__ordered-products-container {
  width: 50%;
}

.o-table-item__total-price {
  width: 25%;
  font-size: $font-medium-big;
  display: flex;
  font-family: "Typpo";
  justify-content: flex-end;
  align-items: center;
  text-align: right;
}

.o-table-item__total-price-header {
  width: 25%;
  text-align: right;
}

.o-table-item__incomingdate {
  width: 57%;
  margin-right: 3%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.o-table-item__count {
  width: 20%;
  text-align: right;
}

@media only screen and (min-width: $screen-large) and (max-width: $screen-other) /* Enormous screen */
{

}

@media only screen and (min-width: $screen-large) and (max-width: $screen-extra-large) /* Big screen */
{

}

@media only screen and (min-width: $screen-medium) and (max-width: $screen-large) /* Big tablet & small desktop*/
{
  .o-table-item {
    padding: 1px 5px;
  }
}

@media only screen and (min-width: $screen-small) and (max-width: $screen-medium) /* Big mobile & small tablet*/
{


}

@media only screen and (max-width: $screen-small) /* Mobile */
{
  .o-table-item {
    padding: 1px 5px;
  }
}