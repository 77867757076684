.s-google {
  position: fixed;
  right: 5px;
  top: 5px;
  z-index: 51;
}

@media only screen and (min-width: $screen-large) and (max-width: $screen-other) /* Enormous screen */
{

}

@media only screen and (min-width: $screen-large) and (max-width: $screen-extra-large) /* Big screen */
{

}

@media only screen and (min-width: $screen-medium) and (max-width: $screen-large) /* Big tablet & small desktop*/
{

}

@media only screen and (min-width: $screen-small) and (max-width: $screen-medium) /* Big mobile & small tablet*/
{


}

@media only screen and (max-width: $screen-small) /* Mobile */
{
  .s-google {
    position: fixed;
    right: 0px;
    top: 0px;
  }
}

@media only screen and (min-width: $screen-quad-hd) /* Big screen */
{

}
@media only screen and (min-width: $screen-4k) /* Enormous screen */
{

}
