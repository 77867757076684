.rma-lesser {
  padding: $spacing-medium 0 0 0;

  .rma-line {
    text-align: center;
    font-size: $font-small;
    color: $grey;

    span {
      padding: 0 5px;
    }
  }
}
@media only screen and (min-width: $screen-4k) /* Enormous screen */ {
  .rma-line {
    font-size: $font-big !important;
  }
}
