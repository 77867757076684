@charset "UTF-8";

@font-face {
  font-family: "ycon";
  src:url("ycon.eot");
  src:url("ycon.eot?#iefix") format("embedded-opentype"),
    url("ycon.woff") format("woff"),
    url("ycon.ttf") format("truetype"),
    url("ycon.svg#ycon") format("svg");
  font-weight: normal;
  font-style: normal;
}

.icon-star-0:before   {content: "\e025 \e025 \e025 \e025 \e025"; letter-spacing: -0.1em;}
.icon-star-0-5:before {content: "\e027 \e025 \e025 \e025 \e025"; letter-spacing: -0.1em;}
.icon-star-1:before   {content: "\e026 \e025 \e025 \e025 \e025"; letter-spacing: -0.1em;}
.icon-star-1-5:before {content: "\e026 \e027 \e025 \e025 \e025"; letter-spacing: -0.1em;}
.icon-star-2:before   {content: "\e026 \e026 \e025 \e025 \e025"; letter-spacing: -0.1em;}
.icon-star-2-5:before {content: "\e026 \e026 \e027 \e025 \e025"; letter-spacing: -0.1em;}
.icon-star-3:before   {content: "\e026 \e026 \e026 \e025 \e025"; letter-spacing: -0.1em;}
.icon-star-3-5:before {content: "\e026 \e026 \e026 \e027 \e025"; letter-spacing: -0.1em;}
.icon-star-4:before   {content: "\e026 \e026 \e026 \e026 \e025"; letter-spacing: -0.1em;}
.icon-star-4-5:before {content: "\e026 \e026 \e026 \e026 \e027"; letter-spacing: -0.1em;}
.icon-star-5:before   {content: "\e026 \e026 \e026 \e026 \e026"; letter-spacing: -0.1em;}

.icon-aquariums:before,
.icon-aquarium:before {
  content: "\e000";
}
.icon-price:before,
.icon-trophy:before,
.icon-award:before {
  content: "\e001";
}
.icon-barbeque:before,
.icon-bbq:before {
  content: "\e002";
}
.icon-car-speed:before {
  content: "\e003";
}
.icon-cart:before {
  content: "\e004";
}
.icon-cart-speed:before {
  content: "\e005";
}
.icon-cart-stacked:before {
  content: "\e006";
}
.icon-ch-down:before {
  content: "\e007";
}
.icon-ch-left:before {
  content: "\e008";
}
.icon-ch-right:before {
  content: "\e009";
}
.icon-ch-up:before {
  content: "\e00a";
}
.icon-circle:before,
.icon-circle-empty:before {
  content: "\e04e";
}
.icon-circle-full:before {
  content: "\e00b";
}
.icon-cross:before {
  content: "\e00c";
}
.icon-cross-box:before {
  content: "\e00d";
}
.icon-cross-box-full:before {
  content: "\e00e";
}
.icon-file:before,
.icon-document:before {
  content: "\e00f";
}
.icon-download:before {
  content: "\e010";
}
.icon-euro:before {
  content: "\e011";
}
.icon-expert:before {
  content: "\e012";
}
.icon-heart:before {
  content: "\e013";
}
.icon-heart-full:before {
  content: "\e014";
}
.icon-spas:before,
.icon-hottub:before {
  content: "\e015";
}
.icon-info-box:before {
  content: "\e016";
}
.icon-info-box-full:before {
    content: "\e017";
}
.icon-instagram:before {
  content: "\e018";
}
.icon-light:before {
  content: "\e019";
}
.icon-light-full:before {
  content: "\e01a";
}
.icon-mail:before {
  content: "\e01b";
}
.icon-megaphone:before,
.icon-blackfriday:before,
.icon-sale:before {
  content: "\e01c";
}
.icon-box-min:before,
.icon-min-box:before {
  content: "\e01d";
}
.icon-con:before,
.icon-box-min-full:before,
.icon-min-box-full:before {
  content: "\e01e";
}
.icon-minus:before {
  content: "\e01f";
}
.icon-luchtbed:before,
.icon-outdoor:before {
  content: "\e020";
}
.icon-pencil:before,
.icon-pen:before {
  content: "\e021";
}
.icon-plus:before {
  content: "\e022";
}
.icon-question-circle-full:before,
.icon-question-box-full:before {
  content: "\e023";
}
.icon-question-circle:before,
.icon-question-box:before,
.icon-box-question:before{
  content: "\e04b";
}
.icon-search:before {
  content: "\e024";
}
.icon-star_empty:before,
.icon-star-empty:before {
  content: "\e025";
}
.icon-star:before,
.icon-star_full:before,
.icon-star-full:before {
  content: "\e026";
}
.icon-star_half:before,
.icon-star-half:before {
  content: "\e027";
}
.icon-talk:before,
.icon-comment-o:before,
.icon-comment:before {
  content: "\e028";
}
.icon-telephone:before {
  content: "\e029";
}
.icon-thumbs-down:before {
  content: "\e02a";
}
.icon-thumbs-down-full:before {
  content: "\e02b";
}
.icon-thumbs-up:before {
  content: "\e02c";
}
.icon-thumbs-up-full:before {
  content: "\e02d";
}
.icon-toppy-box:before {
  content: "\e02e";
}
.icon-toppy-box-speed:before {
  content: "\e02f";
}
.icon-toppy-icon:before {
  content: "\e030";
}
.icon-trash:before {
  content: "\e031";
}
.icon-schop:before,
.icon-tuin:before {
  content: "\e032";
}
.icon-twitter:before {
  content: "\e033";
}
.icon-watersport:before {
  content: "\e034";
}
.icon-whatsapp:before {
  content: "\e035";
}
.icon-youtube:before {
  content: "\e036";
}
.icon-zwembad:before {
  content: "\e037";
}
.icon-chat:before {
  content: "\e038";
}
.icon-check:before {
  content: "\e039";
}
.icon-check-box:before {
  content: "\e03a";
}
.icon-check-box-full:before {
  content: "\e03b";
}
.icon-vijvers:before,
.icon-vijver:before {
  content: "\e03c";
}
.icon-facebook:before {
  content: "\e03d";
}
.icon-filter:before,
.icon-sliders:before{
  content: "\e03e";
}
.icon-garden:before {
  content: "\e03f";
}
.icon-grid:before {
  content: "\e040";
}
.icon-hamburger:before,
.icon-menu:before {
  content: "\e041";
}
.icon-link:before {
  content: "\e042";
}
.icon-linkedin:before {
  content: "\e043";
}
.icon-list:before {
  content: "\e044";
}
.icon-loading:before {
  content: "\e045";
}
.icon-location:before,
.icon-pin:before {
  content: "\e046";
}
.icon-box-plus:before,
.icon-plus-box:before {
  content: "\e047";
}
.icon-pro:before,
.icon-box-plus-full:before,
.icon-plus-box-full:before {
  content: "\e048";
}
.icon-account:before,
.icon-profile:before {
  content: "\e049";
}
.icon-account-full:before,
.icon-profile-full:before {
  content: "\e04a";
}
.icon-car:before {
  content: "\e04c";
}
.icon-kamperen:before,
.icon-tipi:before {
  content: "\e052";
}
.icon-snoeien:before {
  content: "\e050"; 
}
.icon-sport:before,
.icon-spel:before {
  content: "\e051";
}
.icon-tent:before {
  content: "\e053";
}
.icon-snow:before,
.icon-cooling:before {
 content: "\e057";
}
.icon-fire:before,
.icon-heating:before {
 content: "\e056";
}
.icon-timer:before {
 content: "\e04F";
}
.icon-toppy-box-back:before {
 content: "\e04D";
}
.icon-dots-vertical:before {
 content: "\e054";
}
.icon-checklist:before {
 content: "\e058";
}
.icon-capacity:before {
 content: "\e059";
}
.icon-return-check:before {
   content: "\e055";
}
