.o-header {
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 180px;
  display: flex;
  border-radius: $border-radius;
  flex-direction: column;
  align-items: center;
  border-bottom: #fff;
  border: 0;
  margin-bottom: -1px;
  color: $white;

  &__second_number {
    display: flex;
    justify-content: center;
    font-size: 1.5em;
    // margin-top: 1.3em;
    margin-bottom: 10px;
    .DigitRoll {
      color: $white;
      border: none;
    }

    .DigitRoll__Cell {
      color: $white;
      width: 0.75rem !important;
    }
  }
}

.o-header__label {
  .c-label {
    padding-top: 10px;
    padding-bottom: $spacing-medium;
    color: $white;
  }
}

.o-header__number__container {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  align-items: center;
}

.o-header__number {
  display: flex;
  justify-content: center;

  .DigitRoll {
    color: $white;
    border: none;
  }

  .DigitRoll__Cell {
    color: $white;
    width: 1.5rem !important;
  }
}

@media only screen and (min-width: $screen-quad-hd) /* Big screen */ {
  .o-header {
    min-height: 14em;
    &__number {
      margin-top: 1.1em;
      transform: scale(1.3);
    }
  }
}
@media only screen and (min-width: $screen-4k) /* Enormous screen */ {
  .o-header {
    min-height: 23em;
    &__number {
      margin-top: 3.7em;
      transform: scale(2.4);
    }
    &__second_number {
      transform: scale(2.4);
      margin-top: 4.3em;
    }
  }
}

@media only screen and (min-width: $screen-large) and (max-width: $screen-other) /* Enormous screen */ {
}

@media only screen and (min-width: $screen-large) and (max-width: $screen-extra-large) /* Big screen */ {
}

@media only screen and (min-width: $screen-medium) and (max-width: $screen-large) /* Big tablet & small desktop*/ {
}

@media only screen and (min-width: $screen-small) and (max-width: $screen-medium) /* Big mobile & small tablet*/ {
}

@media only screen and (max-width: $screen-small) /* Mobile */ {
  .block #green {
    min-height: unset;
    height: 170px;
  }

  .block #wide-blue {
    min-height: unset;
    height: 170px;
  }
  .block #green2 {
    min-height: unset;
    height: 155px;
  }

  .o-header {
    &__second_number {
      padding-top: 20px;
    }
  }
}
