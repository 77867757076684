
.c-plus {
  position: relative;
  display: inline-block;
  font-size: 22px;
  padding: 5px 25px;
  color: white;
  border-radius: 6px;
  text-align: center;
  transition: top .01s linear;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.c-plus.green:hover {
  background-color: #82c8a0;
}

.c-plus:active {
  top: 9px;
}

.c-plus.green {
  background-color: #82c8a0;
  box-shadow: 0 0 0 1px #82c8a0 inset,
  0 0 0 2px rgba(255, 255, 255, 0.15) inset,
  0 8px 0 0 rgba(126, 194, 155, .7),
  0 8px 0 1px rgba(0, 0, 0, .4),
  0 8px 8px 1px rgba(0, 0, 0, 0.5);
}

.c-plus.green:active {
  box-shadow: 0 0 0 1px #82c8a0 inset,
  0 0 0 2px rgba(255, 255, 255, 0.15) inset,
  0 0 0 1px rgba(0, 0, 0, 0.4);
}