@media only screen and (min-width: $screen-extra-large) and (max-width: $screen-other) {
  .tv {
    font-size: $font-medium;

    .o-number-box {
      .o-number-box__growth {
        font-size: $font-medium-big;
        margin-top: $spacing-small;
      }

      .c-roller_number {
        font-size: $font-extra-big;
      }

      .o-number-box__number .DigitRoll__Cell {
        width: 1rem !important;
      }
    }

    .s-visitors-container {
      font-size: $font-medium-big;
      line-height: $font-big;
    }

    .c-label {
      font-size: $font-medium;
    }

    .c-chart-label {
      font-size: 1rem;
      padding-bottom: $spacing-small;
    }

    .medium-column {
      width: 32vw;
    }

    .color {
      width: 20px;
      height: 20px;
    }

    .o-pie__container {
      align-items: center;
    }

    .c-legend-item .label {
      width: 65%;
    }

    .c-legend-item .percentage {
      width: 20%;
      text-align: center;
    }

    .c-legend-item .value {
      width: 15%;
      text-align: center;
    }

    .o-pie {
      height: 220px;
    }

    .c-legend-item {
      font-size: $font-medium-big;
    }

    .o-header__label p {
      padding-top: 0;
    }

    .c-legend-item-horizontal {
      color: $grey-darker;
    }

    .o-line__container text {
      font-size: $font-medium-small !important;
    }

    #tickets {
      padding-bottom: 21px;
    }

    .o-bar__container {
      height: 60px;
      margin: $spacing-medium 0 0 0;

      .o-bar {
        height: 52px;
        width: 80%;

        text {
          font-size: $font-big !important;
          font-weight: 600;
        }
      }
    }

    #warehouse-process {
      padding: $spacing-medium-big 0;
    }

    .o-performance-bar__container {
      margin: 0 $spacing-medium;
      padding: 24px 0 18px 0;

      .o-performance-bar__bar {
        height: 52px;

        text {
          font-size: $font-big !important;
          font-weight: 600;
        }
      }
    }

    .o-bar__chart-number {
      padding: 0 15px;
      font-size: $font-medium-big;
    }

    .o-progressbar {
      height: 52px;

      text {
        font-size: $font-big !important;
        font-weight: 600;
      }
    }

    .o-line__container {
      padding: 0;
    }

    .o-horizontal-bar .o-horizontal-bar__container .o-horizontal-bar__bar {
      height: 52px;

      f text {
        font-size: $font-big !important;
        font-weight: 600;
      }
    }

    .big-column {
      width: 65vw;
    }
  }
}

@media only screen and (min-width: $screen-4k) /* Enormous screen */ {
  .c-label {
    font-size: 2em !important;
  }
  .c-chart-label {
    font-size: 2em !important;
  }
  .o-number-box__growth {
    font-size: 2em !important;
  }
  .tv .o-line__container text {
    font-size: 2em !important;
  }
}
