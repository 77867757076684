.s-mobile-menu {
  display: none;
}

@media only screen and (min-width: $screen-large) and (max-width: $screen-other) /* Enormous screen */
{

}

@media only screen and (min-width: $screen-large) and (max-width: $screen-extra-large) /* Big screen */
{

}

@media only screen and (min-width: $screen-medium) and (max-width: $screen-large) /* Big tablet & small desktop*/
{

}

@media only screen and (min-width: $screen-small) and (max-width: $screen-medium) /* Big mobile & small tablet*/
{


}

@media only screen and (max-width: $screen-small) /* Mobile */
{
  .s-mobile-menu {
    position: fixed;
    justify-content: space-around;
    width: 100%;
    background-color: $white;
    height: 50px;
    align-items: center;
    display: flex;
    z-index: 10;
    bottom: 0;
    left: 0;
  }
  .s-mobile-menu__item {
    padding: $spacing-small
  }
}