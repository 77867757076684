.o-active-employees__container {
  background-color: $white;
  width: 30vw;
  padding: $spacing-medium;
  border-right: $border-radius;
  box-shadow: $shadow-subtle;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.o-active-employees__title {
  text-decoration: none;
  color: #343e40;
  text-align: center;
  font-family: 'typpo';
  margin: $spacing-medium 0;
  font-size: 1.3rem;
}

.o-active-employee__kpi-container {
  text-align: center;
}

.o-active-employees__buttons {
  display: flex;
  justify-content: space-around;
  width: 100%;
  align-items: center;
  margin: $spacing-medium;
}

.o-active-employee__employees {
  width: 30%;
  text-align: center;
}

@media only screen and (min-width: $screen-large) and (max-width: $screen-other) /* Enormous screen */
{

}

@media only screen and (min-width: $screen-large) and (max-width: $screen-extra-large) /* Big screen */
{

}

@media only screen and (min-width: $screen-medium) and (max-width: $screen-large) /* Big tablet & small desktop*/
{
  .o-active-employees__container {
    width: 55vw;
  }
}

@media only screen and (min-width: $screen-small) and (max-width: $screen-medium) /* Big mobile & small tablet*/
{


  .o-active-employees__container {
    width: 80vw;
  }
}

@media only screen and (max-width: $screen-small) /* Mobile */
{
  .o-active-employees__container {
    width: 80vw;
  }
}
