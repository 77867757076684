.o-line__container {
  padding: $spacing-medium;
  margin-top: $spacing-big;
  #warehouse-line {
    height: 250px;
    margin: 0 $spacing-big;
  }

  #ticket-line {
    height: 200px;
    margin: $spacing-medium $spacing-medium 0 $spacing-medium;
  }

  .o-line__line {
    line {
      stroke: $grey-lighter !important;
    }
  }
}

@media only screen and (min-width: $screen-4k) /* Enormous screen */ {
  .o-line {
    &__line {
      height: 500px !important;
    }
  }
}

@media only screen and (min-width: $screen-large) and (max-width: $screen-other) /* Enormous screen */ {
}

@media only screen and (min-width: $screen-large) and (max-width: $screen-extra-large) /* Big screen */ {
  .o-line__container {
    #warehouse-line {
      margin: 0px 0px;
    }
    #ticket-line {
      margin: $spacing-small 0 0 0;
    }
  }
}

@media only screen and (min-width: $screen-medium) and (max-width: $screen-large) /* Big tablet & small desktop*/ {
  .o-line__container #ticket-line {
    margin: $spacing-small 0 0 0;
  }
  .o-line__container {
    padding: 10px;
  }
  #warehouse-line {
    margin: 0 0;
  }
}

@media only screen and (min-width: $screen-small) and (max-width: $screen-medium) /* Big mobile & small tablet*/ {
  .o-line__container {
    padding: 10px;
    #ticket-line {
      margin: 0;
    }
    #warehouse-line {
      margin: 0 0;
    }
  }
}

@media only screen and (max-width: $screen-small) /* Mobile */ {
  .o-line__container {
    padding: 10px 2px 2px 2px;
    #warehouse-line {
      height: 150px;
      margin: 0px 0px;
    }

    #ticket-line {
      margin: 15px 0px 0 0px;
    }
  }
}
