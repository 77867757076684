.s-choose-dashboard {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
}

.s-choose-dashboard__title {
  font-family: 'typpo';
  font-size: $font-huge;
  padding-bottom: $spacing-big;
  text-align: center;
}


@media only screen and (min-width: $screen-large) and (max-width: $screen-other) /* Enormous screen */
{

}

@media only screen and (min-width: $screen-large) and (max-width: $screen-extra-large) /* Big screen */
{

}

@media only screen and (min-width: $screen-medium) and (max-width: $screen-large) /* Big tablet & small desktop*/
{

}

@media only screen and (min-width: $screen-small) and (max-width: $screen-medium) /* Big mobile & small tablet*/
{
  .s-choose-dashboard{
    width:100vw;
  }

}

@media only screen and (max-width: $screen-small) /* Mobile */
{
  .s-choose-dashboard__title {
    padding-top: 30px;
    padding-bottom: 0;
  }
  .s-choose-dashboard{
    height: unset;
  }
}
