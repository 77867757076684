.o-gauge {
  height: 470px;
  padding: $spacing-medium $spacing-big;
  display: flex;
  justify-content: center;
  flex-direction: column;
  &__header {
    text-align: center;
    .c-label {
      font-family: "Typpo";
      color: #343e40;
      font-size: 2rem !important;
    }
  }
  &__progression {
    margin-top: -159px;

    width: 100%;
    text-align: center;
    &__numbers {
      font-size: $font-medium-big;
    }
    &__percentage {
      font-size: $font-huge;
    }
  }
}

@media only screen and (max-width: $screen-small) /* Mobile */ {
  .o-gauge {
    height: 335px;
    padding: 0px 40px;
    &__header {
      margin-bottom: -80px;
    }
  }
}
@media only screen and (min-width: $screen-quad-hd) /* Big screen */ {
}
@media only screen and (min-width: $screen-4k) /* Enormous screen */ {
  .o-gauge {
    height: 700px;

    &__progression {
      margin-top: -243px;

      &__numbers {
        font-size: 3rem;
      }
      &__percentage {
        font-size: 5.5rem;
      }
    }
  }
}
