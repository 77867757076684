.o-notification__container {
  position: fixed;
  left: 0;
  top: 0;
  color: $orange2;
  background-color: $white;
  border-bottom-right-radius: $border-radius;
  height: 50px;
  display: flex;
  align-items: center;
  box-shadow: $shadow-subtle;
  justify-content: space-between;
  z-index: 50;
}

.o-notification__container__image {
  background-color: $orange2;
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
}

.o-notification__container__text {
  padding: 0 $spacing-medium-small;
}
@media only screen and (min-width: $screen-4k) /* Enormous screen */ {
  .o-notification__container {
    transform: scale(2);
    left: 115px;
    top: 50px;
  }
}
