.c-bar-text {
  font-size: $font-medium-big !important;
  font-family: "Poppins" !important;
  font-weight: 600 !important;

  text {
    font-size: $font-medium-big !important;
    font-family: "Poppins" !important;
    font-weight: 600 !important;
  }
}
