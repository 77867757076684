.o-pie__container {
  display: flex;
  justify-content: space-around;
  width: 100%;

  .o-pie {
    height: 160px;
    margin: 0px $spacing-medium 0px $spacing-medium;
    width: 40%;
  }

  .o-pie__legend {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.pie-box {
  margin: $spacing-big 0;
}

@media only screen and (min-width: $screen-large) and (max-width: $screen-other) /* Enormous screen */
{

}

@media only screen and (min-width: $screen-large) and (max-width: $screen-extra-large) /* Big screen */
{

}

@media only screen and (min-width: $screen-medium) and (max-width: $screen-large) /* Big tablet & small desktop*/
{
  .pie-box{
    .o-pie__legend{
      display: none;
    }
  }
}

@media only screen and (min-width: $screen-small) and (max-width: $screen-medium) /* Big mobile & small tablet*/
{
  .pie-box{
    .o-pie__legend{
      display: none;
    }
  }
}

@media only screen and (max-width: $screen-small) /* Mobile */
{
  .pie-box{
    .o-pie__legend{
      display: none;
    }
  }
}