.s-number-boxes {
  padding: $spacing-medium 0 $spacing-medium 0;
  display: flex;
  justify-content: center;
  text-align: center;
  background-color: $white;
  border-radius: $border-radius;
}

@media only screen and (min-width: $screen-large) and (max-width: $screen-other) /* Enormous screen */
{

}

@media only screen and (min-width: $screen-large) and (max-width: $screen-extra-large) /* Big screen */
{

}

@media only screen and (min-width: $screen-medium) and (max-width: $screen-large) /* Big tablet & small desktop*/
{

}

@media only screen and (min-width: $screen-small) and (max-width: $screen-medium) /* Big mobile & small tablet*/
{


}

@media only screen and (max-width: $screen-small) /* Mobile */
{
  .s-number-boxes {
    justify-content: space-around;
  }
}
@media only screen and (min-width: $screen-quad-hd) /* Big screen */
{
  .o-number-box{
    &__number{
      .c-roller_number{
        transform: scale(1.3)
      }
    }
  }
}
@media only screen and (min-width: $screen-4k) /* Enormous screen */
{
  .o-number-box{
    &__number{
      .c-roller_number{
        transform: scale(2)
      }
    }
  }
}
@media only screen and (min-width: $screen-quad-hd) /* Big screen */
{

}
@media only screen and (min-width: $screen-4k) /* Enormous screen */
{
}