
.c-min {
  position: relative;
  display: inline-block;
  font-size: 22px;
  padding: 5px 25px;
  color: white;
  border-radius: 6px;
  text-align: center;
  transition: top .01s linear;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */

  user-select: none; /* Standard */
}

.c-min.red:hover {
  background-color: $red;
}

.c-min:active {
  top: 9px;
}

/* 3D button colors */
.c-min.red {
  background-color: $red;
  box-shadow: 0 0 0 1px #c63702 inset,
  0 0 0 2px rgba(255, 255, 255, 0.15) inset,
  0 8px 0 0 #C24032,
  0 8px 0 1px rgba(0, 0, 0, 0.4),
  0 8px 8px 1px rgba(0, 0, 0, 0.5);
}

.c-min.red:active {
  box-shadow: 0 0 0 1px #c63702 inset,
  0 0 0 2px rgba(255, 255, 255, 0.15) inset,
  0 0 0 1px rgba(0, 0, 0, 0.4);
}