.o-review__header {
  display: flex;
  align-items: center;
  padding-bottom: $spacing-small;
  justify-content: space-between;

  .o-review__header__title {
    color: $black;
    max-height: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 600;
    width: 80%;
  }

  .o-review__header__stars {
    width: 35%;
    font-size: $font-medium-big;
    text-align: right;
  }
}

.o-review__body {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 100px;
  padding-bottom: $spacing-medium-small;
  overflow: hidden;

  .o-review__body__review {
    height: 100%;
    font-size: $font-small;
    overflow-y: scroll;
    padding-right: 17px; /* Increase/decrease this value for cross-browser compatibility */
    box-sizing: content-box; /* So the width will be 100% + 17px */
    line-height: 1.1rem;
    /* width */
    ::-webkit-scrollbar {
      width: 0px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      border-radius: 0px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      border-radius: 0px;
    }
  }

  .o-review__body__rating {
    font-family: "typpo";
    font-size: $font-big;
    line-height: 1.8rem;
  }
}

.o-review__footer {
  font-size: $font-small;
  display: flex;
  justify-content: flex-start;
  padding-top: $spacing-medium;

  .o-review__footer__date {
    font-weight: 600;
  }
}
@media only screen and (min-width: $screen-large) and (max-width: $screen-other) /* Enormous screen */
{

}

@media only screen and (min-width: $screen-large) and (max-width: $screen-extra-large) /* Big screen */
{

}

@media only screen and (min-width: $screen-medium) and (max-width: $screen-large) /* Big tablet & small desktop*/
{
  .o-review__header .o-review__header__stars{
    width: 40%;
  }
}

@media only screen and (min-width: $screen-small) and (max-width: $screen-medium) /* Big mobile & small tablet*/
{
  .o-review__header .o-review__header__stars{
    width: 40%;
  }
}

@media only screen and (max-width: $screen-small) /* Mobile */
{
  .o-review__header .o-review__header__stars{
    width: 40%;
  }
}
@media only screen and (min-width: $screen-quad-hd) /* Big screen */
{
  .o-review{
    &__header{
      font-size: 1.3em;
    }
    &__body{
      &__review{
        font-size: 1em!important;
        line-height: 1.5em!important;
      }
      &__rating{
        font-size: 3rem!important;
      }

    }
  }
}
@media only screen and (min-width: $screen-4k) /* Enormous screen */
{
  .o-review{
    &__header{
      font-size: 2.3em;
    }

    &__body{
      height: 20em;
      &__review{
        font-size: 1.6em!important;
        line-height: 1.5em!important;
      }
      &__rating{
        font-size: 3rem!important;
      }

    }
  }
  .o-review__header__stars{
    transform: scale(1.5)!important;
    margin-right: 75px;
  }
  .o-review__footer{
    font-size: 1.3em;
  }
}