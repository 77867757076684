
.c-tag {
  position: absolute;
  background-color: $yellow;
  color: $black;
  padding: $spacing-small $spacing-medium;
  border-radius: $border-radius;
  box-shadow: $shadow-subtle;

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translate(-50%, 100%);
    border: 13px solid transparent;
    border-top-color: $yellow;
  }
}


.right-rotate {
  transform: translate(0%, -135%) rotate(random(20) + deg);
  right: 0;
}

.left-rotate {
  transform: translate(0%, -135%) rotate(- random(20) + deg);
  left: 0;
}