//Vragen
.loader-container {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: $white;
  display: flex;
  color: $black;
  justify-content: center;
  align-items: center;
  z-index: 100;
  left: 0;
  opacity: 0.9;
  overflow: hidden;
  top: 0;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
  text-align: center;
  .loader {
    opacity: 1;
  }
  .message {
    margin-top: 5vh;
    font-size: $font-medium-big;
    color: $grey;
  }
}
.loader-container.show {
  opacity: 1;

  visibility: visible;
}
.hide {
  opacity: 0;
  height: 0;
  transition: opacity 2s;
}
@media only screen and (min-width: $screen-4k) /* Enormous screen */ {
  .loader-container {
    transform: scale(2);
  }
}
