.o-login__container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.o-login {
  display: flex;
  align-items: center;
  background-color: $white;
  padding: $spacing-medium;
  width: 80%;
  box-shadow: $shadow-subtle;
}

.o-login__label-big {
  text-align: center;
  font-size: $font-big;

}

.o-login__label-small {
  margin-top: 20px;
  text-align: center;
  font-size: $font-medium;

}

.o-login__image {
  background-image: url('../../img/logo_yellow.png');
  background-size: contain;
  background-repeat: no-repeat;
  height: 184px;
  width: 325px;
}


.o-login__googlebutton {
  margin-top: $spacing-medium;
}