.rainbow {
  -webkit-animation: rainbow-background 12s infinite;
  -ms-animation: rainbow-background 12s infinite;
  -o-animation: rainbow-background 12s infinite;
  animation: rainbow-background 12s infinite;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $font-medium;
  font-weight: 600;
  position: relative;
  width: 100%;
  margin-top: -52px;
  height: 55px;

  z-index: 10;
}

.rainbow-text {

  -webkit-animation: rainbow-text 12s infinite;
  -ms-animation: rainbow-text 12s infinite;
  -o-animation: rainbow-text 12s infinite;
  animation: rainbow-text 12s infinite;
}

.white {
  color: white !important;
}

@-webkit-keyframes rainbow-background {
  0% {
    background-color: #ff0000;
  }
  10% {
    background-color: #ff8000;
  }
  20% {
    background-color: #ffff00;
  }
  30% {
    background-color: #80ff00;
  }
  40% {
    background-color: #00ff00;
  }
  50% {
    background-color: #00ff80;
  }
  60% {
    background-color: #00ffff;
  }
  70% {
    background-color: #0080ff;
  }
  80% {
    background-color: #0000ff;
  }
  90% {
    background-color: #8000ff;
  }
  100% {
    background-color: #ff0080;
  }
}

@-ms-keyframes rainbow-background {
  0% {
    background-color: #ff0000;
  }
  10% {
    background-color: #ff8000;
  }
  20% {
    background-color: #ffff00;
  }
  30% {
    background-color: #80ff00;
  }
  40% {
    background-color: #00ff00;
  }
  50% {
    background-color: #00ff80;
  }
  60% {
    background-color: #00ffff;
  }
  70% {
    background-color: #0080ff;
  }
  80% {
    background-color: #0000ff;
  }
  90% {
    background-color: #8000ff;
  }
  100% {
    background-color: #ff0080;
  }
}

@-o-keyframes rainbow-background {
  0% {
    background-color: #ff0000;
  }
  10% {
    background-color: #ff8000;
  }
  20% {
    background-color: #ffff00;
  }
  30% {
    background-color: #80ff00;
  }
  40% {
    background-color: #00ff00;
  }
  50% {
    background-color: #00ff80;
  }
  60% {
    background-color: #00ffff;
  }
  70% {
    background-color: #0080ff;
  }
  80% {
    background-color: #0000ff;
  }
  90% {
    background-color: #8000ff;
  }
  100% {
    background-color: #ff0080;
  }
}

@keyframes rainbow-background {
  0% {
    background-color: #ff0000;
  }
  10% {
    background-color: #ff8000;
  }
  20% {
    background-color: #ffff00;
  }
  30% {
    background-color: #80ff00;
  }
  40% {
    background-color: #00ff00;
  }
  50% {
    background-color: #00ff80;
  }
  60% {
    background-color: #00ffff;
  }
  70% {
    background-color: #0080ff;
  }
  80% {
    background-color: #0000ff;
  }
  90% {
    background-color: #8000ff;
  }
  100% {
    background-color: #ff0080;
  }
}


@-webkit-keyframes rainbow-text {
  0% {
    color: #ff0000;
  }
  10% {
    color: #ff8000;
  }
  20% {
    color: #ffff00;
  }
  30% {
    color: #80ff00;
  }
  40% {
    color: #00ff00;
  }
  50% {
    clor: #00ff80;
  }
  60% {
    color: #00ffff;
  }
  70% {
    color: #0080ff;
  }
  80% {
    color: #0000ff;
  }
  90% {
    color: #8000ff;
  }
  100% {
    color: #ff0080;
  }
}

@-ms-keyframes rainbow-text {
  0% {
    color: #ff0000;
  }
  10% {
    color: #ff8000;
  }
  20% {
    color: #ffff00;
  }
  30% {
    color: #80ff00;
  }
  40% {
    color: #00ff00;
  }
  50% {
    clor: #00ff80;
  }
  60% {
    color: #00ffff;
  }
  70% {
    color: #0080ff;
  }
  80% {
    color: #0000ff;
  }
  90% {
    color: #8000ff;
  }
  100% {
    color: #ff0080;
  }
}

@-o-keyframes rainbow-text {
  0% {
    color: #ff0000;
  }
  10% {
    color: #ff8000;
  }
  20% {
    color: #ffff00;
  }
  30% {
    color: #80ff00;
  }
  40% {
    color: #00ff00;
  }
  50% {
    clor: #00ff80;
  }
  60% {
    color: #00ffff;
  }
  70% {
    color: #0080ff;
  }
  80% {
    color: #0000ff;
  }
  90% {
    color: #8000ff;
  }
  100% {
    color: #ff0080;
  }
}

@keyframes rainbow-text {
  0% {
    color: #ff0000;
  }
  10% {
    color: #ff8000;
  }
  20% {
    color: #ffff00;
  }
  30% {
    color: #80ff00;
  }
  40% {
    color: #00ff00;
  }
  50% {
    clor: #00ff80;
  }
  60% {
    color: #00ffff;
  }
  70% {
    color: #0080ff;
  }
  80% {
    color: #0000ff;
  }
  90% {
    color: #8000ff;
  }
  100% {
    color: #ff0080;
  }
}
