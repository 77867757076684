.o-number-box {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 40%;
  position: relative;

  .o-number-box__number {
    .c-roller_number {
      font-size: $font-big;

      color: $black;
    }

    .DigitRoll__Cell {
      width: 0.8rem !important;
    }
  }

  .o-number-box__divider {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
@media only screen and (min-width: $screen-large) and (max-width: $screen-other) /* Enormous screen */
{

}

@media only screen and (min-width: $screen-large) and (max-width: $screen-extra-large) /* Big screen */
{

}

@media only screen and (min-width: $screen-medium) and (max-width: $screen-large) /* Big tablet & small desktop*/
{

}

@media only screen and (min-width: $screen-small) and (max-width: $screen-medium) /* Big mobile & small tablet*/
{


}

@media only screen and (max-width: $screen-small) /* Mobile */
{
  .o-number-box {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 50%;
    position: relative;
  }
}

@media only screen and (min-width: $screen-quad-hd) /* Big screen */
{

}
@media only screen and (min-width: $screen-4k) /* Enormous screen */
{
  .c-label{
    padding-bottom: $spacing-medium;
  }
  .o-number-box{
    &__growth{
      font-size: 2em;
      padding-top: $spacing-medium;
    }
  }
}