.o-dashboard-card {
  background-color: $white;
  width: 20vw;
  height: 45vh;
  margin: $spacing-gap;
  border-right: $border-radius;
  box-shadow: $shadow-subtle;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;

  a {
    text-decoration: none;
    color: $black;
    text-align: center;
    font-family: 'typpo';
    font-size: $font-medium-big;
  }


}

.o-dashboard-card__img {
  img {
    width: auto;
    height: auto;
    margin: $spacing-medium;
    border-radius: 0;

  }
}

.o-dashboard-cards {
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (min-width: $screen-large) and (max-width: $screen-other) /* Enormous screen */
{

}

@media only screen and (min-width: $screen-large) and (max-width: $screen-extra-large) /* Big screen */
{

}

@media only screen and (min-width: $screen-medium) and (max-width: $screen-large) /* Big tablet & small desktop*/
{

}

@media only screen and (min-width: $screen-small) and (max-width: $screen-medium) /* Big mobile & small tablet*/
{
  .o-dashboard-cards {
    width: 100vw;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .o-dashboard-card {
      width: 35%;
      height: unset;
      padding: $spacing-medium-big;
      .o-dashboard-card__img img {
        width: 80%;
      }
      .o-dashboard-card__text{
        font-size: $font-medium-big;
      }
    }
  }
}

@media only screen and (max-width: $screen-small) /* Mobile */
{
  .o-dashboard-cards {
    width: 100vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .o-dashboard-card {
      width: 80%;

      .o-dashboard-card__img img {
        width: 80%;
      }
    }
  }

}
