.o-bar__container {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: $spacing-small 0 0 0;

  .o-bar {
    height: 32px;
    width: 60%;
    background-color: $grey-lighter;
    font-family: "Poppins" !important;

    text {
      font-family: "Poppins" !important;
      fill: $white !important;
      font-size: $font-medium !important;
      font-weight: 600;
    }

    line {
      display: none;
    }
  }

  .o-bar__chart-number {
    padding: 0 15px;
    font-size: $font-small;
  }
}

.o-bar__background-container {
  display: flex;
  justify-content: center;
  margin-top: -45px;

  .o-bar__background {
    background-color: #f5f5f5;
    height: 33px;
    width: 50%;
  }
}

@media only screen and (min-width: $screen-4k) /* Enormous screen */ {
  .o-bar {
    height: 100px !important;
    &__chart-number {
      font-size: 2.25rem !important;
    }
  }
}

@media only screen and (min-width: $screen-large) and (max-width: $screen-other) /* Enormous screen */ {
}

@media only screen and (min-width: $screen-large) and (max-width: $screen-extra-large) /* Big screen */ {
}

@media only screen and (min-width: $screen-medium) and (max-width: $screen-large) /* Big tablet & small desktop*/ {
}

@media only screen and (min-width: $screen-small) and (max-width: $screen-medium) /* Big mobile & small tablet*/ {
  .o-bar__container .o-bar {
    width: 80%;
  }
}

@media only screen and (max-width: $screen-small) /* Mobile */ {
  .o-bar__container {
    .o-bar {
      width: 80%;
    }
  }
  .o-bar__background-container {
    .o-bar__background {
      width: 80%;
    }
  }
}
