.o-product__container{
  text-align: center;
  margin-top: $spacing-medium;
  .o-product{
    display: flex;
    width:100%;
    justify-content: space-evenly;
    height: 80px;
    line-height: 80px;

    .o-product__price{
      font-family: 'Typpo';
      font-size: $font-medium-big;
    }
    .o-product__img{
      height: 80px;
      img{
        height: 80px;
        width: 80px;
        margin: 0;
      }
    }
    .o-product__productname{
      overflow: hidden;

      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
@media only screen and (min-width: $screen-large) and (max-width: $screen-other) /* Enormous screen */
{

}

@media only screen and (min-width: $screen-large) and (max-width: $screen-extra-large) /* Big screen */
{
  .o-product__productname{
    max-width:50%;
  }
}

@media only screen and (min-width: $screen-medium) and (max-width: $screen-large) /* Big tablet & small desktop*/
{
  .o-product__productname{
    max-width:40%;
  }
}

@media only screen and (min-width: $screen-small) and (max-width: $screen-medium) /* Big mobile & small tablet*/
{
  .o-product__productname{
    max-width:40%;
  }
}

@media only screen and (max-width: $screen-small) /* Mobile */
{
  .o-product__productname{
    max-width:40%;
  }
}
@media only screen and (min-width: $screen-quad-hd) /* Big screen */
{


}
@media only screen and (min-width: $screen-4k) /* Enormous screen */
{
  .o-product{
    font-size: 1.8em!important;
    padding: $spacing-medium 0;
  }
  .o-product__price{
    font-size: 2em!important;
  }
  .o-product__img{
    width: 10%;
    img{
      width: 100%!important;
      height: 100%!important;
    }
  }
}