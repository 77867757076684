@font-face {
  font-family: "Typpo";
  src: url("../../fonts/Typpo-Heavy.eot");
  src: local("Typpo"), url("../../fonts/Typpo-Heavy.woff2") format("woff2"),
    url("../../fonts/Typpo-Heavy.woff") format("woff"),
    url("../../fonts/Typpo-Heavy.ttf") format("truetype"),
    url("../../fonts/Typpo-Heavy.otf") format("opentype");
  font-style: normal;
  font-weight: 900;
}

@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,700&display=swap");
//main colors
$yellow: #ffcb01;
$red: #f3575e;
$blue: #35acf8;
$green: #12ac74;
$green-light: #12ac74;
$blue-light: #badaef;
$yellow-light: #f8f2b8;
$red-light: #ffe3e3;
$blue-dark: #16356f;

//Greys
$black: #343e40;
$grey-darker: #5f6768;
$grey-darker: #8b9091;
$grey: #b7b9ba;
$grey-light: #e3e3e3;
$grey-lighter: #f5f5f5;
$grey-lightest: #fcfcfc;
$white: #ffffff;

//Stoplichtkleuren
$orange: #ef7102;
$orange2: #ff7801;

//Fontsizes
$font-huge: 3.4rem;
$font-extra-big: 2.5rem;
$font-big: 2rem;
$font-medium-big: 1.25rem;
$font-medium: 1rem;
$font-medium-small: 0.85rem;
$font-small: 0.7rem;

//order set props
$border-radius: 4px;
$gap: 16px;
$header-height: 20vh;

//Margins & paddings
$spacing-gap: 16px;
$spacing-small: 5px;
$spacing-medium-small: 20px;
$spacing-medium: 25px;
$spacing-medium-big: 30px;
$spacing-big: 50px;

//Screensizes
$screen-small: 500px;
$screen-medium: 768px;
$screen-large: 1024px;
$screen-extra-large: 1910px;
$screen-quad-hd: 2000px;
$screen-4k: 3500px;
$screen-other: 100000px;

$shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
$shadow-light: 0px 0px 51px 0px rgba(0, 0, 0, 0.05);
$shadow-subtle: 0 1px 1px 0 rgba(60, 64, 67, 0.08),
  0 1px 3px 1px rgba(60, 64, 67, 0.16);
/* width */
::-webkit-scrollbar {
  width: 0px;
}

ul {
  list-style-type: disc;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}

button {
  -webkit-appearance: unset;
  -webkit-writing-mode: unset;
  text-rendering: unset;
  color: unset;
  letter-spacing: unset;
  word-spacing: unset;
  text-transform: unset;
  text-indent: unset;
  text-shadow: unset;
  display: unset;
  text-align: unset;
  align-items: unset;
  cursor: unset;
  background-color: unset;
  box-sizing: unset;
  margin: unset;
  font: unset;
  padding: unset;
  border-width: unset;
  border-style: unset;
  border-color: unset;
  border-image: unset;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 00px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 0px;
}

p {
  display: block;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

html {
  color: $black;
  width: 100vw;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: "Poppins";
  background-color: $grey-lighter;

  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.warehouse-progress {
  height: 125%;
}

//Vragen
.negative {
  color: $red;
}

.postive {
  color: $green;
}

.blue {
  color: $blue;
}

.orange {
  color: $orange;
}

.red-light {
  color: $red;
}

.alert {
  color: #f00;
  font-weight: 800;
}

.grey {
  color: $grey;
}

.warehouse-process {
  display: flex;
  justify-content: center;
  height: 36vh;
  align-items: center;
}

.typpo {
  font-family: "Typpo";
}

#yellow {
  background-image: url("../../img/markers-full-yellow-1.png");
}

#blue {
  background-image: url("../../img/markers-full-blue-1.png");
}

#blue-flat {
  background-image: url("../../img/markers-full-blue-flat-1.png");
}

#green {
  background-image: url("../../img/markers-full-green-1.png");
}

#green-flat {
  background-image: url("../../img/markers-full-green-1-flat.png");
}

#green2 {
  background-image: url("../../img/markers-full-green-2.png");
}

#wide-blue {
  background-image: url("../../img/wide-markers-full-blue-1.png");
}

.background {
  background-color: $grey-lighter;
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.customer-service {
  display: flex;
  justify-content: center;
}

.c-titleLabel {
  margin-top: $spacing-medium;
  text-align: center;
  width: 100%;
  color: $grey-darker;
  font-size: 0.8rem;
}

.content-dashboard {
  display: flex;
  justify-content: center;
}

.rma-dashboard {
  display: flex;
  justify-content: center;
}

.warehouse {
  padding-bottom: 5px;
  display: flex;
  justify-content: center;
}

.medium-column {
  width: 28vw;
}

.big-column {
  margin: 0 8px;
  width: 56vw;
}

.block {
  border-radius: $border-radius;
  background-color: $white;
  margin-top: $spacing-gap;
  z-index: 2;
}
.block__no_margin {
  border-radius: $border-radius;
  background-color: $white;
  z-index: 2;
}
.rma-content {
  padding: $spacing-medium 0;
  background-color: $white;
}

@media only screen and (min-width: $screen-large) and (max-width: $screen-other) {
  .customer-service {
    flex-direction: row;
  }
  .warehouse {
    flex-direction: row;
  }
  .medium-column {
    margin: 0 8px;
  }
  #warehouse-left {
    width: 56vw;
  }
}

@media only screen and (min-width: $screen-large) and (max-width: $screen-extra-large) {
  .customer-service {
    flex-direction: row;
  }
  .warehouse {
    flex-direction: row;
  }
  .medium-column {
    margin: 0 8px;
  }
  #center {
    width: 31.5vw;
  }
  #left {
    width: 31.5vw;
  }
  #right {
    width: 31.5vw;
  }
}

@media only screen and (min-width: $screen-medium) and (max-width: $screen-large) {
  .customer-service {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100vw;
  }
  .content-dashboard {
    justify-content: space-evenly;
    width: 100vw;
  }
  .warehouse {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100vw;
  }
  #center {
    width: 31.5vw;
  }
  #left {
    width: 31.5vw;
  }

  #right {
    width: 31.5vw;
  }
  #warehouse-left {
    width: 58vw;
  }
  #warehouse-right {
    width: 35vw;
  }
}

@media only screen and (min-width: $screen-small) and (max-width: $screen-medium) {
  .customer-service {
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
  }
  .content-dashboard {
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
  }
  .warehouse {
    flex-wrap: wrap;
    width: 100vw;
    justify-content: space-evenly;
    flex-direction: row;
  }
  .medium-column {
    width: 96vw;
  }
  #left {
    width: 75vw;
  }
  #right {
    width: 75vw;
  }
  #center {
    width: 75vw;
  }
  #warehouse-left {
    width: 48%;
  }
  #warehouse-right {
    width: 48%;
  }
}

@media only screen and (max-width: $screen-small) {
  .customer-service {
    flex-direction: column;
    padding: 5vh 0;
  }
  .content-dashboard {
    flex-direction: column;
    padding: 5vh 0;
  }
  .warehouse {
    flex-direction: column;
    padding: 1vh 0;
  }
  .medium-column {
    width: 94vw;
    margin: 0 3vw;
  }

  .big-column {
    margin: 0 8px;
    width: 94vw;
  }
  .content-dashboard {
    flex-wrap: wrap;
  }
}

@media only screen and (min-width: $screen-4k) /* Enormous screen */ {
  .medium-column {
    width: 32vw !important;
  }
  .content-dashboard {
    width: 100vw;
    justify-content: space-evenly;
  }
  .block {
    margin-top: 2em;
  }
  .c-titleLabel {
    font-size: 1.6rem;
  }
}
