$progressbar-height: 42px;

.o-progressbar-container {
  padding: $spacing-medium $spacing-big;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.o-progressbar__container {
  margin: 12px 0;
  b &:first-child {
    margin: 0 0 $spacing-medium 0;
  }
}
.o-progressbar {
  height: $progressbar-height;
  background-color: $grey-lighter;
}
.o-progressbar__hide {
  padding: $spacing-big;
}
.o-progressbar__labels {
  display: flex;
  justify-content: space-between;
  font-size: $font-medium-big;
  padding: 0 0 $spacing-small 0;
}

.bad {
  color: $red;
}
.okay {
  color: #ef7102;
}
.good {
  color: $green;
}
.incredible {
  color: $green;
}

@media only screen and (min-width: $screen-4k) /* Enormous screen */ {
  .o-progressbar {
    height: 130px !important;
  }
  .numbers {
    font-size: $font-big;
    margin-top: -30px;
  }
}
@media only screen and (min-width: $screen-large) and (max-width: $screen-other) /* Enormous screen */ {
}

@media only screen and (min-width: $screen-large) and (max-width: $screen-extra-large) /* Big screen */ {
}

@media only screen and (min-width: $screen-medium) and (max-width: $screen-large) /* Big tablet & small desktop*/ {
}

@media only screen and (min-width: $screen-small) and (max-width: $screen-medium) /* Big mobile & small tablet*/ {
}

@media only screen and (max-width: $screen-small) /* Mobile */ {
  .o-progressbar-container {
    padding: 10px;
  }
}
