.popup {
  z-index: 101;
  color: $black;
  padding-bottom: $spacing-small;
}

img {
  width: 80px;
  height: 80px;
  margin: 15px;
  border-radius: 50%;
}

.popup__close {
  position: relative;

  img {
    position: absolute;
    width: 20px;
    height: 20px;
    cursor: pointer;
    right: 0px;
  }
}

.popup__hidden {
  img {
    cursor: pointer;
  }
}

.popup__header {
  display: flex;
  color: $black;
  align-items: center;
  flex-direction: inherit;
  justify-content: center;
  font-size: $font-medium;
}

.popup__name {
  font-weight: 500;
}

.popup__email {
  color: $grey;
  font-size: $font-medium-small;
}

.popup__dashboards {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .popup__dashboard {
    width: 100%;
    padding: $spacing-small 0;
    height: 40px;
    line-height: 40px;
    color: $black;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      background-color: $yellow-light;
      color: $black;
      -webkit-transition: 0.2s; /* Safari prior 6.1 */
      transition: 0.2s;
    }

    .c-settings {
      position: absolute;
      top: 12px;
      right: 12px;
    }
  }

  .popup__dashboard__active {
    background-color: $grey-lighter;
  }
}

.popup__dashboard__settings {
  position: relative;

  .c-settings {
    width: 25px;
    height: 25px;
    cursor: pointer;
    position: absolute;
    margin-top: -38px;
    right: 13px;
  }
}

.popup__logout {
  padding: 10px 0;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: $yellow-light;
    color: $black;
    -webkit-transition: 0.2s; /* Safari prior 6.1 */
    transition: 0.2s;
  }
}

.popup__show {
  background-color: $white;
  z-index: 1;
  box-shadow: $shadow;
  border-radius: $border-radius;
  width: 305px;
  margin: 0 50px;
}


@media only screen and (min-width: $screen-large) and (max-width: $screen-other) /* Enormous screen */
{

}

@media only screen and (min-width: $screen-large) and (max-width: $screen-extra-large) /* Big screen */
{

}

@media only screen and (min-width: $screen-medium) and (max-width: $screen-large) /* Big tablet & small desktop*/
{

}

@media only screen and (min-width: $screen-small) and (max-width: $screen-medium) /* Big mobile & small tablet*/
{


}

@media only screen and (max-width: $screen-small) /* Mobile */
{
  .popup__show {
    width: 100vw;
    margin: 0;
    border-radius: 0 0 $border-radius $border-radius;
  }
}
@media only screen and (min-width: $screen-quad-hd) /* Big screen */
{

}
@media only screen and (min-width: $screen-4k) /* Enormous screen */
{
  .popup__header{
    font-size: 1em!important;
  }
  .popup__show {
    width: 20vw;
    font-size: 2em;
    margin: 0;

  }
  .popup__close img{
    width: 50px;
    height: 50px;
  }
  .popup__dashboard__settings .c-settings{
    width: 40px;
    height: 40px;
    margin-top: -72px;
  }
  .popup__dashboard{
    height: 3em!important;
    line-height: 3em!important;
  }
}

