.o-bar-grouped-container {
    height: 220px;
    line {
        stroke: $grey-lighter;
    }
    text {
        font-family: "Poppins" !important;
        fill: $grey !important;
    }
}
