.o-horizontal-bar-grouped__container {
  padding: $spacing-medium 0 $spacing-medium 0;

  .o-horizontal-bar-grouped {
    line {
      display: none !important
    }

    .o-horizontal-bar-grouped__numbers {
      display: flex;
      justify-content: space-between;
      margin-top: -15px;
      padding-left: 14.8%;
      padding-right: 4.2%;
      font-size: $font-medium-big;
      color: $grey;
    }
  }

}

@media only screen and (min-width: $screen-large) and (max-width: $screen-other) /* Enormous screen */
{

}

@media only screen and (min-width: $screen-large) and (max-width: $screen-extra-large) /* Big screen */
{

}

@media only screen and (min-width: $screen-medium) and (max-width: $screen-large) /* Big tablet & small desktop*/
{

}

@media only screen and (min-width: $screen-small) and (max-width: $screen-medium) /* Big mobile & small tablet*/
{


}

@media only screen and (max-width: $screen-small) /* Mobile */
{
  .o-horizontal-bar-grouped__container .o-horizontal-bar-grouped .o-horizontal-bar-grouped__numbers {
    padding: 0 3%;
  }
}