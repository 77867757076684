.DigitRoll {
  color: $black;
  border: none !important;
  overflow: hidden;
}

.DigitRoll__Cell {
  color: $black;
  margin: 0 3px;
  border: 0px solid;
}
