.c-label{
    color: #b7b9ba;
    font-size: $font-small;
}

@media only screen and (min-width: $screen-large) and (max-width: $screen-other) /* Enormous screen */
{

}

@media only screen and (min-width: $screen-large) and (max-width: $screen-extra-large) /* Big screen */
{

}

@media only screen and (min-width: $screen-medium) and (max-width: $screen-large) /* Big tablet & small desktop*/
{

}

@media only screen and (min-width: $screen-small) and (max-width: $screen-medium) /* Big mobile & small tablet*/
{

}

@media only screen and (max-width: $screen-small) /* Mobile */
{

}

@media only screen and (min-width: $screen-quad-hd) /* Big screen */
{
    .c-label{
        font-size: 1em;
    }
}
@media only screen and (min-width: $screen-4k) /* Enormous screen */
{
    .c-label{
        font-size: 1.7em;
    }

}
