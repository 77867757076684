.s-review {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: $spacing-medium;
}
@media only screen and (min-width: $screen-quad-hd) /* Big screen */
{

}
@media only screen and (min-width: $screen-4k) /* Enormous screen */
{
    .s-review{
        padding: 4em;
    }

}