//Vragen
.s-visitors-container {
  text-align: center;
  font-size: $font-small;
  padding: 19px; //Vragen
}
@media only screen and (min-width: $screen-large) and (max-width: $screen-other) /* Enormous screen */ {
}

@media only screen and (min-width: $screen-large) and (max-width: $screen-extra-large) /* Big screen */ {
}

@media only screen and (min-width: $screen-medium) and (max-width: $screen-large) /* Big tablet & small desktop*/ {
}

@media only screen and (min-width: $screen-small) and (max-width: $screen-medium) /* Big mobile & small tablet*/ {
  #visitors {
    margin-bottom: $spacing-medium;
  }
}

@media only screen and (max-width: $screen-small) /* Mobile */ {
}

@media only screen and (min-width: $screen-quad-hd) /* Big screen */ {
}
@media only screen and (min-width: $screen-4k) /* Enormous screen */ {
  #visitors {
    font-size: 2em;
  }

  .s-visitors-container {
    font-size: 2.3em !important;
    div {
      padding: $spacing-medium-small 0;
    }
  }
}
