.s-table-container {
  display: flex;
  font-size: $font-small;
  justify-content: center;
  flex-direction: row;
  padding-bottom: $spacing-medium;
}

.s-table-container__box {
  width: 90%;
  overflow: hidden;
  height: 300px;
}

.high-order {
  position: absolute;
  top: 50%;
  left: 30%;
  z-index: 100;
  right: 30%;
  background-color: $yellow;
  color: $black;
  border: none;
  box-shadow: $shadow;
}

.high-order-in-table {
  background-color: $yellow;
}

.confetti {
  position: fixed;
  top: 50%;
  left: 50%;
}
@media only screen and (min-width: $screen-quad-hd) /* Big screen */
{

}
@media only screen and (min-width: $screen-4k) /* Enormous screen */
{
  .s-table-container__box{
    height: 60em!important;
  }
  .s-table-container{
    height: 60em!important;
  }
  .o-table-item{
    font-size: 2em!important;
  }
}