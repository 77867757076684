.o-performance-bar__container {
  margin: 0 $spacing-big;
  padding: 24px 0 33px 0;

  .o-performance-bar__bar {
    height: 32px;
    margin-top: $spacing-small;
    background-color: $grey-lighter;

    line {
      display: none !important;
    }
  }

  .o-performance-bar__numbers {
    margin-top: $spacing-small;
    display: flex;
    justify-content: space-between;
    text-align: center;
  }
}

@media only screen and (min-width: $screen-4k) /* Enormous screen */ {
  .o-performance-bar__container .o-performance-bar__numbers {
    font-size: 2em !important;
  }
}

@media only screen and (min-width: $screen-large) and (max-width: $screen-other) /* Enormous screen */ {
}

@media only screen and (min-width: $screen-large) and (max-width: $screen-extra-large) /* Big screen */ {
}

@media only screen and (min-width: $screen-medium) and (max-width: $screen-large) /* Big tablet & small desktop*/ {
}

@media only screen and (min-width: $screen-small) and (max-width: $screen-medium) /* Big mobile & small tablet*/ {
}

@media only screen and (max-width: $screen-small) /* Mobile */ {
  .o-performance-bar__container {
    margin: 0 15px;
  }
}
@media only screen and (min-width: $screen-quad-hd) /* Big screen */ {
}
@media only screen and (min-width: $screen-4k) /* Enormous screen */ {
  .o-performance-bar__bar {
    height: 5em !important;
    text {
      font-size: 1.5em !important;
    }
  }
  .o-performance-bar__numbers {
    font-size: 1.5em !important;
  }
}
