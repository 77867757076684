
.o-tv-switch {
  display: none;
}


@media only screen and (min-width: $screen-large) and (max-width: $screen-other) /* Enormous screen */
{
  .o-tv-switch {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: $spacing-medium 0;
  }

  .o-tv-switch__label {
    padding: 0 $spacing-small;
  }

  .o-tv-switch__switch {
    display: flex;
    justify-content: center;
    align-items: center;
  }

}

@media only screen and (min-width: $screen-large) and (max-width: $screen-extra-large) /* Big screen */
{

}

@media only screen and (min-width: $screen-medium) and (max-width: $screen-large) /* Big tablet & small desktop*/
{

}

@media only screen and (min-width: $screen-small) and (max-width: $screen-medium) /* Big mobile & small tablet*/
{


}

@media only screen and (max-width: $screen-small) /* Mobile */
{

}

@media only screen and (min-width: $screen-quad-hd) /* Big screen */
{

}
@media only screen and (min-width: $screen-4k) /* Enormous screen */
{
  .o-tv-switch__switch{
    transform: scale(1.5);
    margin-left: 1em;
  }
}