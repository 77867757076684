.c-legend-item {
    display: flex;
    justify-content: flex-start;
    font-size: $font-small;
    padding: 4px 0px;

    .label {
        color: $black;

        width: 60%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .value {
        width: 20%;
    }
    .percentage {
        width: 20%;
    }
    .color {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: $spacing-small;
    }
}
